<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_105_24)">
      <path
        d="M237.05 0H18.9C8.45 0 0 8.25 0 18.45V237.5C0 247.7 8.45 256 18.9 256H237.05C247.5 256 256 247.7 256 237.55V18.45C256 8.25 247.5 0 237.05 0ZM75.95 218.15H37.95V95.95H75.95V218.15ZM56.95 79.3C44.75 79.3 34.9 69.45 34.9 57.3C34.9 45.15 44.75 35.3 56.95 35.3C69.1 35.3 78.95 45.15 78.95 57.3C78.95 69.4 69.1 79.3 56.95 79.3ZM218.15 218.15H180.2V158.75C180.2 144.6 179.95 126.35 160.45 126.35C140.7 126.35 137.7 141.8 137.7 157.75V218.15H99.8V95.95H136.2V112.65H136.7C141.75 103.05 154.15 92.9 172.6 92.9C211.05 92.9 218.15 118.2 218.15 151.1V218.15Z"
        fill="#282F66"
      />
    </g>
    <defs>
      <clipPath id="clip0_105_24">
        <rect
          width="256"
          height="256"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
